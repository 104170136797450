// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-change-email-tsx": () => import("./../src/pages/change-email.tsx" /* webpackChunkName: "component---src-pages-change-email-tsx" */),
  "component---src-pages-check-email-tsx": () => import("./../src/pages/check-email.tsx" /* webpackChunkName: "component---src-pages-check-email-tsx" */),
  "component---src-pages-email-verification-tsx": () => import("./../src/pages/email-verification.tsx" /* webpackChunkName: "component---src-pages-email-verification-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-recovery-expired-tsx": () => import("./../src/pages/password-recovery/expired.tsx" /* webpackChunkName: "component---src-pages-password-recovery-expired-tsx" */),
  "component---src-pages-password-recovery-index-tsx": () => import("./../src/pages/password-recovery/index.tsx" /* webpackChunkName: "component---src-pages-password-recovery-index-tsx" */),
  "component---src-pages-password-recovery-success-tsx": () => import("./../src/pages/password-recovery/success.tsx" /* webpackChunkName: "component---src-pages-password-recovery-success-tsx" */)
}

